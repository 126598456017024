<template>
  <div>
    <div :class="[`box-icon ${size}`]">
      <img class="mb-3" :src="`/img/${image}`" />
    </div>
  </div>
</template>
<script>
export default {
  name: "box-icon",
  props: {
    image: String,
    size: { type: String, default: "md" },
  },
};
</script>
<style>
.md {
  width: 80px;
  height: 80px;
}
.sm {
  width: 35px;
  height: 35px;
}
.box-icon {
  margin: 0px 0px 16px 16px;
  padding: 6px 6px;
  border-radius: 3px !important;
}
</style>
