<template>
  <div>
    <div v-if="is_loading || !wallet_data">
      <loading />
    </div>

    <div class="row" v-else>
      <div class="col-xl-7 col-md-12" v-if="botType != 2">
        <chart
          :botId="botId"
          :botType="botType"
          :betSide="setting.bet_side"
          :setting_data="setting"
          :jsonIndex="0"
          ref="openchart"
        />
      </div>
      <div class="col-xl-7 col-md-12" v-else>
        <chart
          :botId="botId"
          :botType="botType"
          :betSide="setting.bet_side"
          :setting_data="setting"
          :jsonIndex="1"
          ref="openchart"
        />
      </div>
      <div class="col-xl-5 col-md-12 space">
        <board
          @create_bot="create_bot"
          @success="success"
          @get_wallet="get_wallet"
          :setting_data="setting"
          :setting_status="status"
          :wallet="wallet_data"
          :botId="botId"
          :botType="botType"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "./chart/Chart";

import Board from "./Board";
import Loading from "@/components/Loading";

export default {
  components: {
    Chart,

    Board,
    Loading,
  },
  props: {
    wallet: {
      type: Object,
    },
  },
  mounted() {
    this.get_wallet();
    this.sockets.subscribe(`wallet${this.$store.getters.getUserId}`, (data) => {
      this.loading = false;
      if (data.wallet != null) {
        this.wallet_data = data.wallet;
      } else {
        this.get_wallet();
      }
      console.log("wallet", data.wallet);
    });

    this.$http
      .get(
        `${process.env.VUE_APP_BACKEND_API_URL}/user_bot/${this.$store.getters.getUserId}`
      )
      .then(({ data }) => {
        if (data.data.bot != null) {
          this.setting = data.data.bot;
          this.botId = this.setting.id;
          this.status = true;
          this.botType = this.setting.bot_type;
        } else {
          this.botType = Number(this.wallet.botType);
        }

        this.is_loading = false;
      })
      .catch((err) => {
        this.errorMsg = err.response.data.message;
        return;
      });
  },
  data() {
    return {
      is_loading: true,
      setting: {},
      status: false,
      botId: null,
      wallet_data: null,
      botType: null,
      timer: 30,
      api_status: false,
      count: 0,
    };
  },
  computed: {},
  methods: {
    checkTimeUp(date1, date2) {
      if (localStorage.getItem("trialTimeUp") != "ever") {
        var time_difference = date2.getTime() - date1.getTime();
        var days_difference = time_difference / (1000 * 60 * 60 * 24);

        if (days_difference >= 1) {
          this.$store.dispatch("logout").then(() => {
            this.$router.push("/login").catch(() => {});
          });
        }
      }
    },
    getDate() {
      return (
        new Date().getMonth() +
        "/" +
        new Date().getDate() +
        "/" +
        new Date().getFullYear() +
        " " +
        new Date().getHours() +
        ":" +
        new Date().getMinutes() +
        ":" +
        new Date().getSeconds()
      );
    },

    success() {
      this.is_loading = false;
    },
    get_wallet() {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/wallet/${this.$store.getters.getUserId}`
        )
        .then(({ data }) => {
          this.api_status = data.success;
          if (data.success != true && this.count < 2) {
            this.get_wellet();
            this.count += 1;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    create_bot(data) {
      this.setting = data;

      window.location.reload();
    },
  },

  beforeDestroy() {},
};
</script>
<style>
</style>
