  <template>
  <div :class="[`wallet_fixed`]" id="pc1" style="">
    <div :class="[`hidden_tag`]" @click="pcsh1()">
      <card type="chart" class="text-center">
        <h5 class="m-0" v-if="!hidden">▼</h5>
        <h5 class="m-0" v-if="hidden">▲</h5>
      </card>
    </div>

    <card type="chart mb-2">
      <template slot="header">
        <h5 class="card-category">
          {{ !isTrial ? `กระเป๋าเงินหลัก` : `กระเป๋าเงินทดลอง` }}
        </h5>
        <h3 class="card-title">
          {{ setting.init_wallet }}

          <h5>
            ถอน {{ setting.deposite_count }} ครั้ง ({{ setting.profit_wallet }}
            บาท)
          </h5>
        </h3>
      </template>

      <div class="col-lg-6 col-md-6 col-sm-8 corner" v-if="isTrial">
        <button
          type="submit"
          style="padding: 2px 15px; font-size: 0.75rem"
          :class="['btn btn-primary btn-lg w-100 ', 'btn-primary']"
          @click="wallet_reset"
        >
          รีเซตเงิน
        </button>
      </div>
      <!-- <div style="padding: 14px" v-if="bot_created"></div>
      <div style="padding: 32px" v-if="!bot_created"></div> -->
      <!-- <div style="padding: 51px" class="lg-hidden" v-if="!bot_created"></div> -->
      <hr class="style1 mt-0" style="background-color: white; opacity: 0.1" />
      <div class="row" v-if="!bot_created">
        <div class="col-lg-6"></div>
        <div class="col-lg-12" v-if="!create_loading">
          <button
            type="submit"
            class="btn btn-danger btn-sm w-100"
            @click="create_bot()"
          >
            <h2 class="m-0"><b>สร้างบอท</b></h2>
          </button>
        </div>
        <div class="col-lg-12" v-else>
          <button type="submit" class="btn btn-danger btn-md w-100">
            <pulse-loader :color="'#fff'" :size="'4px'"></pulse-loader>
          </button>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-3" v-if="bot_status">
          <button
            type="submit"
            class="btn btn-info btn-md w-100 p-2"
            @click="pause_bot"
          >
            พักบอท
          </button>
        </div>

        <div class="col-3" v-if="!bot_status">
          <button
            type="submit"
            class="btn btn-success btn-md w-100 p-2"
            @click="pause_bot"
          >
            <div style="margin-left: 1px" v-if="!connect">
              <pulse-loader :color="'#fff'" :size="'4px'" c> </pulse-loader>
            </div>
            <div v-else>เริ่มบอท</div>
          </button>
        </div>
        <div class="col-3" v-if="!bot_reverse">
          <button
            type="submit"
            class="btn btn-warning btn-md w-100 p-2"
            @click="set_opposite"
          >
            สวนบอท
          </button>
        </div>
        <div class="col-3" v-if="bot_reverse">
          <button
            type="submit"
            class="btn btn-info btn-md w-100 p-2"
            @click="set_opposite"
          >
            ตามบอท
          </button>
        </div>

        <div class="col-4" v-if="!open_zero && botType === 2">
          <button
            type="submit"
            class="btn btn-primary btn-md w-100 p-2"
            @click="set_zero"
          >
            แทงศูนย์
          </button>
        </div>
        <div class="col-4" v-if="open_zero && botType === 2">
          <button
            type="submit"
            class="btn btn-info btn-md w-100 p-2"
            @click="set_zero"
          >
            ไม่แทงศูนย์
          </button>
        </div>

        <div class="col-3" v-if="!open_tie && botType != 2 && botType != 3">
          <button
            type="submit"
            class="btn btn-primary btn-md w-100 p-2"
            @click="set_tie"
          >
            แทงเสมอ
          </button>
        </div>
        <div class="col-3" v-if="open_tie && botType != 2 && botType != 3">
          <button
            type="submit"
            class="btn btn-info btn-md w-100 p-2"
            @click="set_tie"
          >
            ไม่แทงเสมอ
          </button>
        </div>
        <div class="col-3">
          <button
            type="submit"
            class="btn btn-danger btn-md w-100 p-2"
            @click="destroy_bot"
          >
            หยุดบอท
          </button>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  components: { PulseLoader },
  computed: {
    isTrial() {
      return localStorage.getItem("trial_active") != "false" ? true : false;
    },
  },
  props: {
    botType: { type: Number, default: 1 },
    setting: Object,
    bot_created: Boolean,
    bot_status: Boolean,
    bot_reverse: Boolean,
    open_zero: Boolean,
    open_tie: Boolean,
    connect: Boolean,
  },
  mounted() {},

  data() {
    return { hidden: false, errorMsg: "" };
  },
  methods: {
    create_bot() {
      this.$emit("create_bot");
    },
    destroy_bot() {
      this.$emit("destroy_bot");
    },
    set_opposite() {
      this.$emit("set_opposite");
    },
    set_zero() {
      this.$emit("set_zero");
    },
    set_tie() {
      this.$emit("set_tie");
    },
    wallet_reset() {
      this.$http
        .post(`${process.env.VUE_APP_BACKEND_API_URL}/set_mock_wallet`, {
          username: this.$store.getters.getDisplayName,
          wallet: 20000,
        })
        .then(({ data }) => {
          window.location.reload();
          return data;
        })
        .catch((err) => {
          return err;
        });
    },
    pcsh1() {
      var x = document.getElementById("pc1");
      if (x.classList.contains("hide")) {
        x.classList.remove("hide");
        this.hidden = false;
      } else {
        x.classList.add("hide");

        this.hidden = true;
      }
    },

    pause_bot() {
      if (this.connect) {
        if (this.bot_status) {
          this.$http
            .post(`${process.env.VUE_APP_BACKEND_API_URL}/pause`, {
              username: this.$store.getters.getDisplayName,
            })
            .then(({}) => {
              //this.bot_status = false;
              this.$emit("set_bot_status", false);
            })
            .catch((err) => {
              this.errorMsg = err.response.data.message;
              return;
            });
        } else {
          this.$http
            .post(`${process.env.VUE_APP_BACKEND_API_URL}/start`, {
              username: this.$store.getters.getDisplayName,
            })
            .then(({}) => {
              // this.bot_status = true;
              this.$emit("set_bot_status", true);
            })
            .catch((err) => {
              this.errorMsg = err.response.data.message;
              return;
            });
        }
      }
    },
  },
  beforeDestroy() {},
};
</script>
<style>
.corner {
  display: flex;
  position: absolute;
  top: 30px;
  right: 0;
}
.hidden_tag {
  width: 15%;
  position: absolute;
  display: flex;
  z-index: 10000;
  top: -50px;
  right: 0;
  cursor: pointer;
}
.wallet_fixed {
  width: 52.5%;
  position: fixed;
  display: flex;
  z-index: 10000;
  bottom: -20px;
  left: 80px;
  /* left: 0; */
}

#pc1 {
  transition-duration: 0.5s;
  transform: translateY(0em);
}

#pc1.hide {
  transform: translateY(13.8em);
}
.space {
  margin-bottom: 160px;
}

.lg-hidden {
  display: none;
}
@keyframes hidden {
  from {
    transform: translateY(0em);
  }
  to {
    transform: translateY(15em);
  } /* slide down to make room for advertisements */
}

@media screen and (max-width: 991px) {
  .wallet_fixed {
    width: 100%;
    position: fixed;
    display: flex;
    z-index: 10000;

    left: 0;
  }
  .space {
    margin-bottom: 160px;
  }
  #pc1.hide {
    transform: translateY(13.5em);
  }
  .lg-hidden {
    display: block;
  }
}

@media screen and (max-width: 576px) {
  .corner {
    display: block;
    position: relative;
    top: 0;
    right: 0;
  }
  #pc1.hide {
    transform: translateY(13.8em);
  }
  .lg-hidden {
    display: block;
  }
}
</style>