<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <box-icon image="money.png" />

              <div class="col-10">
                <h5 class="card-category">
                  {{ !isTrial ? `กระเป๋าเงินหลัก` : `กระเป๋าเงินทดลอง` }}
                </h5>
                <h3 class="card-title">
                  <div>
                    {{ !loading && wallet != null ? `${wallet} บาท` : "" }}
                    <pulse-loader
                      v-if="loading || wallet === null"
                      :color="color"
                      :size="size"
                    ></pulse-loader>
                  </div>
                  <h5>
                    ถอน {{ setting.deposite_count }} ครั้ง ({{
                      setting.profit_wallet
                    }}
                    บาท)
                  </h5>
                </h3>
              </div>
              <div class="corner" v-if="isTrial">
                <button
                  type="submit"
                  :class="[
                    'btn btn-primary btn-xs w-100 btn-sm',
                    'btn-primary',
                  ]"
                  @click="wallet_reset"
                >
                  รีเซตเงิน
                </button>
              </div>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

import BoxIcon from "@/components/Cards/BoxIcon";

export default {
  components: { PulseLoader, BoxIcon },
  data() {
    return {
      setting: {
        deposite_count: 0,
        profit_wallet: 0,
      },
      transfer: "",
      wallet: { data: {} },
      error: false,
      loading: true,
      color: "#fff",
      size: "6px",
      api_status: false,
      count: 0,
      get_wellet_count: 0,
    };
  },
  computed: {
    isTrial() {
      return localStorage.getItem("trial_active") != "false" ? true : false;
    },
  },
  methods: {
    async with_draw() {
      this.transfer = "wait";
      this.$http
        .post(`${process.env.VUE_APP_BACKEND_API_URL}/transfer_wallet/ae`, {
          username: this.$store.getters.getDisplayName,
        })
        .then(({ data }) => {
          // console.log(data);
          this.get_wellet();
          this.transfer = "success";
        })
        .catch((err) => {
          this.transfer = "fail";
          return;
        });
    },
    async get_wellet() {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/wallet/${this.$store.getters.getUserId}`
        )
        .then(({ data }) => {
          this.api_status = data.success;
          if (data.success != true && this.count < 2) {
            this.get_wellet();
            this.count += 1;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
          this.errorMsg = err.response.data.message;
          return;
        });
    },
    wallet_reset() {
      this.$http
        .post(`${process.env.VUE_APP_BACKEND_API_URL}/set_mock_wallet`, {
          username: this.$store.getters.getDisplayName,
          wallet: 20000,
        })
        .then(({ data }) => {
          window.location.reload();
          return data;
        })
        .catch((err) => {
          return err;
        });
    },
  },
  mounted() {
    this.get_wellet();

    this.sockets.subscribe(`wallet${this.$store.getters.getUserId}`, (data) => {
      if (!this.error) {
        this.wallet = data.wallet;
        this.loading = false;
        if (data.wallet != null) {
          this.$emit("callback", this.wallet);
        } else {
          //    this.get_wellet();
        }
      }
    });
  },
};
</script>
<style>
.corner {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
}

@media screen and (max-width: 576px) {
  .corner {
    display: block;
    position: relative;
    top: 0;
    right: 0;
  }
  #pc1.hide {
    transform: translateY(21.4em);
  }
}
</style>
